import { Plugin, PluginKey } from '@atlaskit/editor-prosemirror/state';
import { Decoration, DecorationSet } from '@atlaskit/editor-prosemirror/view';
import type { Node } from '@atlaskit/editor-prosemirror/model';

export type PMNode = {
	isText: boolean;
	isInline: boolean;
	type: {
		name: string;
	};
	text?: string | null;
	content?: {
		size: number;
		content: PMNode[];
	};
	nodeSize: number;
} & Node;

export type AdfNode = {
	type: string;
	text?: string;
	content?: AdfNode[];
	attrs?: {
		[key: string]: string;
	};
};

const SPECIAL_NODES = ['media', 'embedCard', 'blockCard', 'caption'];
const isBlock = (node: AdfNode) => !!node.content;
const isText = (node: AdfNode) => node.type === 'text';
const isInline = (node: AdfNode) => !isBlock(node) && !isText(node) && node.type !== 'media';
const isSpecial = (node: AdfNode) => SPECIAL_NODES.indexOf(node.type) !== -1;
/**
 * Calculates the character count of a ADF-Document. NOTE - any changes to this function must be reflected in our
 * backend as well!
 *
 * - Each character in a text node counts as 1
 * - An inline node (like mention or emoji) counts as 1, regardless of the size. Except "hardBreak", which counts as 0.
 * - Block nodes (like paragraph, bullet list, etc) counts as 0
 * - Media nodes are a bit special (they're wrapped in mediaSingle, which is a block node). But combined they still count as 0.
 */
export const getCharacterCount = (content: AdfNode[]): number => {
	return content.reduce((size, node) => {
		if (isSpecial(node)) {
			return size;
		} else if (isBlock(node)) {
			return size + getCharacterCount(node.content!);
		} else if (isText(node)) {
			return size + node.text!.length;
		} else if (isInline(node) && node.type !== 'hardBreak') {
			return size + 1;
		}
		return size;
	}, 0);
};

/**
 * Calculates the character count of a Prosemirror-Document as described above - but will also map the character at position
 * MAX_CONTENT_SIZE to a resolved position in the prosemirror document so that we can highlight the content overflow with a
 * decoration.
 */
export const getMappedPositions = (doc: PMNode, maxContentSize: number) => {
	let pos = 0;
	let characterCount = 0;
	let breakPointPosition = 0;

	function countNode(node: PMNode) {
		if (characterCount === maxContentSize) {
			breakPointPosition = pos;
		}

		if (node.type.name === 'caption') {
			// Count the size of the node (text content) + 1 for the node boundary itself.
			pos += (node.content?.size ?? 0) + 1;
		} else if (node.isText && node.nodeSize) {
			for (let i = 0; i < node.nodeSize; i++) {
				if (characterCount === maxContentSize) {
					breakPointPosition = pos;
				}
				pos++;
				characterCount++;
			}
		} else if (node.isInline) {
			pos++;

			if (node.type.name !== 'hardBreak') {
				characterCount++;
			}
		} else {
			if (node.type.name !== 'doc') {
				pos++;
			}

			for (const child of node.content?.content) {
				countNode(child);
			}

			if (node.type.name !== 'doc') {
				pos++;
			}
		}
	}

	countNode(doc);

	return {
		characterCount,
		breakPointPosition,
	};
};

type Subscription = (state: ContentOverflowPluginState) => void;
export class ContentOverflowPluginStateSubscription {
	subscriptions: Subscription[] = [];
	lastChange;

	subscribe(subscription: Subscription) {
		this.subscriptions.push(subscription);
		subscription(this.lastChange);
		return () => this.subscriptions.filter((sub) => sub === subscription);
	}

	change(state: ContentOverflowPluginState) {
		this.lastChange = state;
		for (const sub of this.subscriptions) {
			sub(state);
		}
	}
}

export interface ContentOverflowPluginState {
	isActive: boolean;
	positions: ReturnType<typeof getMappedPositions>;
}
class ViewPlugin {
	editorView;
	subscription;
	initialized = false;

	constructor(editorView, subscription) {
		this.editorView = editorView;
		this.subscription = subscription;
	}

	update(view) {
		const pluginState: ContentOverflowPluginState = key.getState(view.state);
		this.subscription.change(pluginState);
	}
}

export const key = new PluginKey('content-overflow');
export const contentOverflowPlugin = (maxContentSize: number) => {
	const subscription = new ContentOverflowPluginStateSubscription();
	const plugin = new Plugin<ContentOverflowPluginState>({
		key,
		state: {
			init: () => ({
				isActive: false,
				positions: {
					characterCount: 0,
					breakPointPosition: 0,
				},
			}),
			apply: (tr) => {
				const positions = getMappedPositions(tr.doc as PMNode, maxContentSize);
				if (positions.characterCount > maxContentSize) {
					return { isActive: true, positions };
				}

				return {
					isActive: false,
					positions,
				};
			},
		},
		props: {
			decorations(state) {
				const { doc } = state;
				const { isActive, positions } = key.getState(state);

				if (isActive && positions) {
					return DecorationSet.create(doc, [
						Decoration.inline(positions.breakPointPosition, doc.nodeSize, {
							class: 'editor-content-overflow',
							'data-testId': 'editor-content-overflow',
						}),
					]);
				}
				return null;
			},
		},

		view(editorView) {
			return new ViewPlugin(editorView, subscription);
		},
	});

	return { plugin, subscription };
};
