import React, { useMemo, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ReactRenderer } from '@atlaskit/renderer';
import type { DocNode } from '@atlaskit/adf-schema';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { token } from '@atlaskit/tokens';
import type { ThemeProps, ThemeTokens } from '@atlaskit/button';
import { CustomThemeButton } from '@atlaskit/button';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import { EmojiResource } from '@atlaskit/emoji/resource';

import { getEmojiProvider } from '@confluence/fabric-providers';
import {
	SSR_PARTIAL_COMPONENT,
	setSSRPartialSuccess,
	checkSSRPartialSuccess,
	SSRActionLoadingSpinner,
} from '@confluence/ssr-utilities';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';
import { getMark } from '@confluence/performance';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { getCharacterCount } from './contentOverflowPlugin';
import {
	isCharacterCountSingleLine,
	isBannerContentSingleLineFromDocNode,
} from './isBannerContentSingleLine';
import { ADMIN_ANNOUNCEMENT_BANNER_METRIC } from './perf.config';
import {
	BANNER_HEIGHT_SINGLE_LINE,
	BANNER_HEIGHT_MULTI_LINE,
} from './useRegisterAdminAnnouncementBanner';
import { useMigratorPromptContent } from './useMigratorPromptContent';

const i18n = defineMessages({
	announcement: {
		id: 'admin-announcement-banner.announcement.icon',
		defaultMessage: 'Announcement',
		description: 'Announcement icon on admin announcement banner',
	},
	dismiss: {
		id: 'admin-announcement-banner.dismiss.button',
		defaultMessage: 'Dismiss admin announcement banner',
		description: 'Dismiss button for admin announcement banner',
	},
	dismissText: {
		id: 'admin-announcement-banner.dismiss.button.text',
		defaultMessage: 'Close',
		description: 'Dismiss button text for admin announcement banner',
	},
});

export enum Appearance {
	ANNOUNCEMENT = 'announcement',
	INFO = 'info',
	NOTE = 'note',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
}

export const bannerBackgroundColors: Record<Appearance, string> = {
	announcement: token('color.background.neutral.bold'),
	info: token('color.background.accent.blue.bolder'),
	note: token('color.background.accent.purple.bolder'),
	success: token('color.background.accent.green.bolder'),
	warning: token('color.background.accent.yellow.subtle'),
	error: token('color.background.accent.red.bolder'),
};

const bannerTextColors: Record<Appearance, string> = {
	announcement: token('color.text.inverse'),
	info: token('color.text.inverse'),
	note: token('color.text.inverse'),
	success: token('color.text.inverse'),
	warning: token('color.text'),
	error: token('color.text.inverse'),
};

type BannerIconColorTypes = 'var(--ds-icon-inverse)' | 'var(--ds-icon)';
export const bannerIconColors: Record<Appearance, BannerIconColorTypes> = {
	announcement: token('color.icon.inverse'),
	info: token('color.icon.inverse'),
	note: token('color.icon.inverse'),
	success: token('color.icon.inverse'),
	warning: token('color.icon'),
	error: token('color.icon.inverse'),
};

const BANNER_CONTENT_HEIGHT_SINGLE_LINE = 24;
const BANNER_CONTENT_HEIGHT_MULTI_LINE = 48;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContentParagraph = styled.p<{
	maxHeight: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ maxHeight }) => maxHeight,
	height: 'auto',
	overflowY: 'auto',
	// Below properties make scrollbar always visible for Chrome and Safari users.
	// This doesn't apply to Firefox users- scrollbar will appear in accordance with device settings,
	// which by default is only showing the scrollbar when scrolling.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar': {
		'-webkit-appearance': 'none',
		width: '10px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar-thumb': {
		borderRadius: '5px',
		backgroundColor: token('color.interaction.hovered'),
		'-webkit-box-shadow': `0 0 1px ${token('color.skeleton')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContentWrapper = styled.div<{
	textColor: string;
	linkDisabled?: boolean;
}>({
	overflow: 'hidden',
	flex: 1,
	margin: `0 ${token('space.150')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ textColor }) => textColor,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		textDecoration: 'underline',
		fontWeight: token('font.weight.bold'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ linkDisabled }) => linkDisabled && { pointerEvents: 'none', cursor: 'default' },
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:hover, a:visited, a:focus, a:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ textColor }) => textColor,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Banner = styled.div<{
	background: string;
	height: string;
}>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => height,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ background }) => background,
	padding: `0 ${token('space.150')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DismissSpan = styled.span<{
	textColor: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ textColor }) => textColor,
});

type DismissHandler = () => void;

const useHandleSsrDismiss = (onDismiss: DismissHandler | undefined) => {
	useSubmitSSRScriptErrors('ssr-dismiss-admin-announcement-button');

	useEffect(() => {
		if (window.__SSR_EVENTS_CAPTURE__?.dismissAdminAnnouncementBannerButton && onDismiss) {
			onDismiss();
			delete window.__SSR_EVENTS_CAPTURE__.dismissAdminAnnouncementBannerButton;
		}
	}, [onDismiss]);
};

const useBrowserMetric = () => {
	useEffect(() => {
		if (checkSSRPartialSuccess(SSR_PARTIAL_COMPONENT.AdminAnnouncementBanner)) {
			ADMIN_ANNOUNCEMENT_BANNER_METRIC.markFMP(getMark('CFP-63.ssr-ttr'));
		}
	}, []);
};

const dismissButtonTheme = (
	currentTheme: (props: ThemeProps) => ThemeTokens,
	themeProps: ThemeProps,
) => {
	const { buttonStyles, spinnerStyles } = currentTheme(themeProps);
	return {
		buttonStyles: {
			...buttonStyles,
			backgroundColor: token('color.background.inverse.subtle'),
			'&:hover': {
				backgroundColor: token('color.background.inverse.subtle.hovered'),
			},
			'&:active': {
				backgroundColor: token('color.background.inverse.subtle.pressed'),
			},
		},
		spinnerStyles,
	};
};

export type AdminAnnouncementBannerComponentProps = {
	onClick?: (e: any) => void;
	content: DocNode;
	appearance: Appearance;
	isDismissible: boolean;
	onDismiss?: DismissHandler;
	userId: string | null;
	cloudId: string;
	onError?: (error: any) => void;
	onComplete?: (stat: any) => void;
	interactive?: boolean;
	shouldRenderMigratorPrompt?: boolean;
};

export const AdminAnnouncementBannerComponent: React.FC<AdminAnnouncementBannerComponentProps> = ({
	onClick,
	content,
	appearance,
	isDismissible,
	onDismiss,
	userId,
	cloudId,
	onError,
	onComplete,
	interactive = true,
	shouldRenderMigratorPrompt = false,
}) => {
	const { formatMessage } = useIntl();
	const providers = useMemo<ProviderFactory>(() => {
		const emojiProvider = getEmojiProvider(EmojiResource, {
			cloudId,
			userId,
		});
		return ProviderFactory.create({
			emojiProvider,
		});
	}, [userId, cloudId]);

	useHandleSsrDismiss(onDismiss);
	useBrowserMetric();

	const migratorPromptContent = useMigratorPromptContent();
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const onRenderComplete = useCallback(
		(stat) => {
			setSSRPartialSuccess(SSR_PARTIAL_COMPONENT.AdminAnnouncementBanner);
			onComplete && onComplete(stat);
		},
		[onComplete],
	);

	const displayContent = shouldRenderMigratorPrompt ? migratorPromptContent : content;

	return (
		<>
			<Banner
				onClick={onClick}
				background={bannerBackgroundColors[appearance]}
				height={`${
					isBannerContentSingleLineFromDocNode(displayContent)
						? BANNER_HEIGHT_SINGLE_LINE
						: BANNER_HEIGHT_MULTI_LINE
				}px`}
				role="banner"
				data-vc="admin-announcement-banner"
				{...ssrPlaceholderIdProp}
			>
				<MegaphoneIcon
					spacing="spacious"
					label={formatMessage(i18n.announcement)}
					color={bannerIconColors[appearance]}
				/>
				<BannerContentWrapper textColor={bannerTextColors[appearance]} linkDisabled={!interactive}>
					<ReactRenderer
						document={displayContent}
						appearance="comment"
						adfStage="stage0"
						dataProviders={providers}
						nodeComponents={{
							paragraph: (props) => (
								<BannerContentParagraph
									{...props}
									maxHeight={`${
										isCharacterCountSingleLine(getCharacterCount(props.content))
											? BANNER_CONTENT_HEIGHT_SINGLE_LINE
											: BANNER_CONTENT_HEIGHT_MULTI_LINE
									}px`}
								/>
							),
						}}
						onComplete={onRenderComplete}
						onError={onError}
					/>
				</BannerContentWrapper>
				{isDismissible && (
					<>
						{/* eslint-disable-next-line check-react-ssr-usage/no-react-ssr */}
						{process.env.REACT_SSR && (
							<SSRActionLoadingSpinner
								spinnerId="dismiss-admin-announcement-banner-loading-spinner"
								actionType="dismissAdminAnnouncementBannerButton"
								spinnerAppearance={appearance !== Appearance.WARNING ? 'invert' : 'inherit'}
							/>
						)}
						<CustomThemeButton
							spacing="compact"
							data-id="ssr-dismiss-admin-announcement-button"
							onClick={onDismiss}
							isDisabled={!interactive}
							aria-label={formatMessage(i18n.dismiss)}
							theme={dismissButtonTheme}
						>
							<DismissSpan textColor={bannerTextColors[appearance]} data-vc="dismiss-button-text">
								{formatMessage(i18n.dismissText)}
							</DismissSpan>
						</CustomThemeButton>
					</>
				)}
			</Banner>
			<PageSegmentLoadEnd metric={ADMIN_ANNOUNCEMENT_BANNER_METRIC} />
		</>
	);
};
